﻿var educationSeachpage = {
    init: function () {
        var lang = $("html").attr("lang");

        function createFlag(option) {
            return "<img class='flag-img' src='" + option.IconUri + "' style='width:24px;height:20px' aria-hidden='true' />";
        }

        function getInputWrapper() {
            return $('.selectize-input');
        }

        function getInput() {
            return $('.selectize-input').find('input');
        }

        function getFlagImage() {
            return $('.selectize-input').find('img');
        }

        function hideResults() {
            $('.js-result-container').hide();
        }

        function getCrossButton() {
            return $('#js-clear-selected-country');
        }
        
        $('.show-text').keyup(function (e) {
            if ($('.show-more').css('display') === 'inline') {
                e.stopPropagation();
                var maxHeight = infoText[0].scrollHeight;
                infoText.animate({
                    'height': maxHeight                 
                });
                infoText.css('max-height', 'none');
                $('.show-less').show();
                $('.show-more').hide();
                infoText[0].setAttribute('aria-expanded', 'true');
                infoText.removeClass('expandable');
            } else if ($('.show-less').css('display') === 'inline') {
                e.stopPropagation();
                if ($(window).width() < 992) {
                    infoText.animate({
                        'height': '115px'
                    });
                } else {
                    infoText.animate({
                        'height': '240px'
                    });
                }
                $('.show-less').hide();
                $('.show-more').show();
                infoText[0].setAttribute('aria-expanded', 'false');
                infoText.addClass('expandable');
            }
        });

    }
};

var educationComparison = {
    init: function () {
        $('[data-toggle="tooltip"]').tooltip();
    }
};

var bedomningCountrySearch = {
    init: function () {
        function showHideMenu() {
            var ned = $('.bedomning-dropdown-button').find('.icon-triangel-pil-ned');
            if ($(ned).css('display') == 'none' || $(ned).css("visibility") == "hidden") {
                hideMenu();
            }
            else {
                showMenu();
            }
        }
        function hideMenu() {
            var menu = $('.bedomning-dropdown-menu');
            var button = $('.bedomning-dropdown-button');
            var ned = $('.bedomning-dropdown-button').find('.icon-triangel-pil-ned');
            var upp = $('.bedomning-dropdown-button').find('.icon-triangel-pil-upp');
            menu.hide();
            ned.show();
            upp.hide();
            button.attr('aria-expanded', false);
        }
        function showMenu() {
            var menu = $('.bedomning-dropdown-menu');
            var button = $('.bedomning-dropdown-button');
            var ned = $('.bedomning-dropdown-button').find('.icon-triangel-pil-ned');
            var upp = $('.bedomning-dropdown-button').find('.icon-triangel-pil-upp');
            menu.show();
            ned.hide();
            upp.show();
            button.attr('aria-expanded', true);
        }
        $('.bedomning-dropdown-button').on("mouseup", function (e) {
            showHideMenu();
        });
        $('.bedomning-dropdown-button').keyup(function (e) {
            var select_land = $('.select-land').val();
            var original_land = $('.select-land').attr('value');
            var flag_img = $('.bedomning-dropdown-button').find('.flag-img');
            if (e.key === 'Enter') {
                showHideMenu();

                if (hasLand) {
                    var universe = $('.bedomning-dropdown-menu').find('a');
                    var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" });
                    var count = 0;
                    var href = '';
                    list.forEach(item => {
                        if ($(item).attr('data-option-name').toLowerCase().includes(select_land.toLowerCase())) {
                            count++;
                            href = $(item).attr('href');
                        }
                    });
                    if (count === 1) {
                        window.location.href = href;
                    }
                }

            } else if (e.key === "ArrowDown") {
                showMenu();
                $('.bedomning-dropdown-menu').find('a').first().focus();
            } else if (e.key === 'Escape' || e.key === 'Esc') {
                if (original_land === '') {
                    $('.select-land').val('');
                    flag_img.css("visibility", "hidden");
                } else {
                    $('.select-land').val(original_land);
                    flag_img.css("visibility", "visible");
                }
                showHideMenu();
            } else {
                showMenu();
                if (select_land != original_land) {
                    flag_img.css("visibility", "hidden");
                } else if (select_land.trim().length > 0 && select_land.trim() === original_land.trim()) {
                    flag_img.css("visibility", "visible");
                }
                if (select_land.trim().length === 0) {
                    showAllCountry();
                } else if (select_land.trim() === original_land.trim()) {
                    showAllCountry();
                } else {
                    hideCountry(select_land);
                }
            }
        });
        var selectHasFocus = false;
        var menuHasFocus = false;
        $('.select-land').focus(function (e) {
            selectHasFocus = true;
            setTimeout(function () {
                showMenu();
            }, 100);
        });
        $('.select-land').blur(function (e) {
            selectHasFocus = false;
            setTimeout(function () {
                if (!selectHasFocus && !menuHasFocus) {
                    hideMenu();
                    var select_land = $('.select-land').val();
                    var original_land = $('.select-land').attr('value');
                    if (select_land === original_land) {
                        var flag_img = $('.bedomning-dropdown-button').find('.flag-img');
                        flag_img.css("visibility", "visible");
                    }
                }
            }, 100);
        });
        $('.bedomning-dropdown-menu .bedomning-dropdown-menu_item a').focus(function (e) {
            menuHasFocus = true;
            setTimeout(function () {
                showMenu();
            }, 100);
        });
        $('.bedomning-dropdown-menu .bedomning-dropdown-menu_item a').blur(function (e) {
            menuHasFocus = false;
            setTimeout(function () {
                if (!selectHasFocus && !menuHasFocus) {
                    hideMenu();
                }
            }, 100);
        });
        var hasLand = false;
        function hideCountry(select_land) {
            var universe = $('.bedomning-dropdown-menu').find('a');
            hasLand = false;
            var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" });
            list.forEach(item => {
                if (!$(item).attr('data-option-name').toLowerCase().includes(select_land.toLowerCase())) {
                    $(item).parent().hide();
                } else {
                    $(item).parent().show();
                    hasLand = true;
                };
            });
            if (hasLand) {
                $('.emptySearch').hide();
            } else {
                $('.emptySearch').show();
            }
        }
        function showAllCountry() {
            var universe = $('.bedomning-dropdown-menu').find('a');
            var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" });
            list.forEach(item => $(item).parent().show());
        }
        function previousFocus(optionId) {
            var universe = $('.bedomning-dropdown-menu').find('a');
            var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" });
            var index = list.map(e => $(e).attr('data-option-id')).indexOf(optionId);
            var a = list[index - 1] || list[list.length - 1];
            a.focus();
        }
        function nextFocus(optionId) {
            var universe = $('.bedomning-dropdown-menu').find('a');
            var list = Array.prototype.filter.call(universe, function (item) { return item.tabIndex >= "0" });
            var index = list.map(e => $(e).attr('data-option-id')).indexOf(optionId);
            var a = list[index + 1] || list[0];
            a.focus();
        }
        $('.bedomning-dropdown-menu_item').keyup(function (e) {
            if (e.key === "ArrowUp") {
                e.preventDefault();
                var a = $(this).find('a');
                var optionId = a.attr('data-option-id');
                previousFocus(optionId);
                e.stopPropagation();
            }
            else if (e.key === "ArrowDown") {
                e.preventDefault();
                var a = $(this).find('a');
                var optionId = a.attr('data-option-id');
                nextFocus(optionId);
                e.stopPropagation();
            }
            else if (e.key === "Escape") {
                showHideMenu();
                $('.bedomning-dropdown-button').focus();
            }
        });
    }
};

$(document).ready(function () {
    if ($('.bedomning-dropdown-button').length) {
        bedomningCountrySearch.init();
    }
    if ($('.bedomnings-jamforelse-tjanst').length) {
        educationSeachpage.init();
    } else if ($('.bedomnings-tjanst-comparison-page').length) {
        educationComparison.init();
    }
});